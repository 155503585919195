import React from 'react';
import { ProjectImg } from '../assets';

export default function Image({ src, size, className }) {
  const Sizes = {
    xs: 'w-[1.5em] h-[1.5em]',
    sm: 'w-[2em] h-[2em]',
    md: 'w-[2.5em] h-[2.5em]',
    large: 'w-[2.625em] h-[2.625em]',
    xl: 'w-[3em] h-[3em]',
    xl2: 'w-[17.063em] h-[16.438em]',
    xl3: 'w-[20.25em] h-[25.563em]',
  };
  return (
    <img src={src?.startsWith('https://') ? src : `${process.env.REACT_APP_SERVER_URL}/${src}`} onError={(e) => { e.target.src = ProjectImg; }} className={`${Sizes[size]} ${className}`} alt="images" />
  );
}
