import { createSlice } from '@reduxjs/toolkit';

const chatsSlice = createSlice({
  name: 'chats',
  initialState: {
    pagination: { chatHasNext: true, msgHasNext: true },
    chatScroll: false,
    chats: [],
    selected: { msg: [], chatId: null },
    attach: { attachment: [], links: [] },
    previewData: {},
    actionModal: { show: false, chatData: null },
    selectedUsers: [],
  },
  reducers: {
    setPaginates: (state, action) => {
      state.pagination[action.payload.name] = action.payload.value;
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    setUpdateChat: (state, action) => {
      const oldChats = [...state.chats];
      const indx = oldChats.findIndex((c) => c.id === action.payload.id);
      oldChats[indx] = action.payload;
      state.chats = oldChats;
    },
    setChatScroll: (state) => {
      state.chatScroll = !state.chatScroll;
    },
    removeChat: (state, action) => {
      const oldChats = [...state.chats];
      state.chats = oldChats.filter((c) => c.id !== action.payload);
    },
    setSelected: (state, action) => {
      //
      state.selected = action.payload;
    },
    setLoadMessage: (state, action) => {
      state.selected.msg = [...action.payload, ...state.selected.msg];
    },
    setMessage: (state, action) => {
      state.selected.msg = [...state.selected.msg, action.payload];
      state.chatScroll = !state.chatScroll;
    },
    setActionModal: (state, action) => {
      state.actionModal = action.payload;
    },
    setSelectedUsers: (state, action) => {
      const prevSelected = [...state.selectedUsers];
      const arr = Array.isArray(action.payload)
        ? [...prevSelected, ...action.payload] : [...prevSelected, action.payload];
      state.selectedUsers = arr;
    },
    removeSelectedUsers: (state, action) => {
      state.selectedUsers = state.selectedUsers.filter((u) => u.id !== action.payload);
    },
    setAttach: (state, action) => {
      state.attach = action.payload;
    },
    setPreviewData: (state, action) => {
      state.previewData = action.payload;
    },
    resetState: (state, action) => {
      state[action.payload.name] = [];
    },
  },
});

export const {
  setChatScroll,
  setPaginates,
  setChats,
  setUpdateChat,
  removeChat,
  setSelected,
  setLoadMessage,
  setMessage,
  setActionModal,
  setSelectedUsers,
  removeSelectedUsers,
  setAttach,
  resetState,
  setPreviewData,
} = chatsSlice.actions;
export default chatsSlice.reducer;
