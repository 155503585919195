import React from 'react';
import { useGlobalCtx } from '../../Context/GlobalProvider';
import Image from '../Image';
import CallBtn from './CallBtn';

export function Caller({
  name,
  avatar,
  mode,
  onDecline,
  onAccept,
}) {
  return (
    <div className="bg-info dark:bg-bgDark top-0 p-5 rounded-xl flex flex-col space-y-8 border dark:border-thirdInfo">
      <div className="flex space-x-3">
        <Image src={avatar} className="w-[3rem] h-[3rem] rounded-full" />
        <div>
          <h1 className="font-[600] text-sm dark:text-info">
            {name}
          </h1>
          <span className="text-CBlue text-xs">
            Incoming
            {` ${mode || ''} `}
            call...
          </span>
        </div>
      </div>
      {/* Footer */}
      <div className="mt-auto flex space-x-3">
        <CallBtn title="Decline" onClick={onDecline} />
        <CallBtn title="Accept" onClick={onAccept} />
      </div>
    </div>
  );
}

export default function IncommingCall() {
  const { callerOP, setCallerOP, callWindows } = useGlobalCtx();

  const decline = (id) => {
    setCallerOP((prev) => prev.filter((d) => d.chatId !== id));
  };

  const accept = (id, mode) => {
    callWindows[id] = window.open(`/call/${mode}/${id}`, '_blank', 'width=auto, height=auto');
    callWindows[id].onclose = () => delete callWindows[id];
    setCallerOP((prev) => prev.filter((d) => d.chatId !== id));
  };

  return (
    <div className="callerScroll absolute bottom-10 right-10 overflow-y-auto scroll-smooth space-y-2 pt-14 max-h-screen">
      {callerOP?.map((data) => (
        <div key={data.chatId}>
          <Caller
            name={data.user.name}
            avatar={data.user.avatar}
            mode={data.mode}
            onDecline={() => decline(data.chatId)}
            onAccept={() => accept(data.chatId, data.mode)}
          />
        </div>
      ))}
    </div>
  );
}
