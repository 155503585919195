import React from 'react';

export default function Loading3() {
  return (
    <div>

      <div className="col-3">
        <div className="snippet" data-title="dot-pulse">
          <div className="stage">
            <div className="dot-pulse relative w-[10px] h-[10px] bg-secondary text-secondary rounded-md" />
          </div>
        </div>
      </div>
    </div>
  );
}
