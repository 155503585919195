import React from 'react';
import {
  Error,
  Information,
  Success,
  Warning,
} from '../../../../assets';
import SVGIcon from '../../../../Components/SVGIcon';
import useTimeout from '../../Hooks/useTimeout';
import './CustomAlert.css';

const alertColor = {
  error: '#F66358',
  success: '#2EB886',
  warning: '#FFA92B',
  info: '#46A8F5',
};

const alertIcons = {
  error: <SVGIcon Icon={Error} />,
  success: <SVGIcon Icon={Success} />,
  warning: <SVGIcon Icon={Warning} />,
  info: <SVGIcon Icon={Information} />,
};

export default function CustomAlert({ message = 'Empty Message', variant = 'success', close = () => { } }) {
  useTimeout(close, 5000);
  return (
    <section className="snackbar-container">
      <div style={{ backgroundColor: alertColor[variant] }} className="custom-snackbar">
        <div className="alert-content">
          <p className="text-white">{alertIcons[variant]}</p>
          <p className="text-sm">{message}</p>
        </div>
      </div>
    </section>
  );
}
