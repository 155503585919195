import React, { createContext, useContext, useMemo } from 'react';
import useArchive from '../hooks/useArchive';

const context = createContext();

export default function ArchiveProvider({ children }) {
  const value = { ...useArchive() };
  const memoized = useMemo(() => value);

  return (
    <context.Provider value={memoized}>
      {children}
    </context.Provider>
  );
}

export const useArchiveCtx = () => useContext(context);
