import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import req from '../../../Utils/req';
import { setValue } from '../reducers/archiveReducer';
import AlertToster from '../../../Components/AlertToster';

const useArchive = () => {
  const controller = new AbortController();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setValue({ target: 'loading', value: true }));
    req({ uri: `project?${new URLSearchParams({ visibility: 'hide', paginate: 'false' })}`, signal: controller.signal })
      .then(({ data }) => {
        dispatch(setValue({ target: 'archivedProjects', value: data }));
      })
      .catch((e) => console.log(e))
      .finally(() => dispatch(setValue({ target: 'loading', value: false })));

    return () => {
      controller.abort();
    };
  }, []);

  const unArchive = (id) => {
    req({ uri: `unarchiveproject/${id}`, method: 'PATCH', signal: controller.signal })
      .then(({ data }) => {
        if (data.id) {
          AlertToster('Project Unarchived', 'success');
        }
      })
      .catch((e) => AlertToster(e.response.data, 'warning'));
    return () => {
      controller.abort();
    };
  };

  return {
    unArchive,
  };
};

export default useArchive;
