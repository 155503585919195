import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useDispatch } from 'react-redux';
import ringtoneSound from '../assets/audio/ringtone.mp3';
import { addNotify } from '../Reducers/notifyReducer';
import { setPUser } from '../Features/Projects/projectReducers';
// import RandomNum from '../Utils/RandomNum';
const ringtone = new Audio(ringtoneSound);
ringtone.loop = true;
ringtone.autoplay = false;

const callWindows = {};

/**
 * socket - function that creates a socket connection
 *
 * @return {Object}  - returns an instance of socket.io with the specified URL and options
 */
const socket = io(process.env.REACT_APP_SOCKET_URL || 'http://localhost:9998', {
  withCredentials: true,
  autoConnect: false,
});

const useGlobal = () => {
  const dispatch = useDispatch();
  const [settingsModal, setSettingsModal] = useState(false);
  const [callerOP, setCallerOP] = useState([]);
  const [modals, setModals] = useState({});

  // for temporary purpose only
  useEffect(() => {
    if (callerOP.length > 0) {
      ringtone.play();
    } else ringtone.pause();
  }, [callerOP]);

  useEffect(() => {
    socket.off('sentNotify');
    socket.on('newUser', (data) => {
      dispatch((update, state) => {
        const oldUser = state().projectStore.pUsers;
        update(setPUser([data, ...oldUser]));
      });
    });
    socket.on('sentNotify', (data) => {
      dispatch(addNotify(data));
    });
    socket.on('incomingCall', (data) => {
      setCallerOP((d) => ([
        ...d,
        data,
      ]));
      setTimeout(() => {
        setCallerOP((prev) => prev.filter((d) => d.chatId !== data.chatId));
      }, 60000);
    });
    socket.on('closeNotification', (data) => {
      setCallerOP((prev) => prev.filter((d) => d.chatId !== data.chatId));
    });
    return () => {
      socket.off('incomingCall');
      socket.off('closeNotification');
      socket.off('newUser');
      socket.off('sentNotify');
    };
  }, []);

  /**
   * notifySubscribe - function to subscribe user for push notifications
   *
   * @return {Promise} - returns a Promise that resolves with the push subscription if successful
   * or rejects with an error message if unsuccessful.
   */
  const notifySubscribe = () => new Promise((resolve, reject) => {
    navigator.serviceWorker.ready.then((reg) => {
      reg.pushManager.getSubscription().then((exists) => {
        if (exists !== null) resolve(exists);
        reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: process.env.REACT_APP_PUBLIC_VAPID,
        })
          .then((sub) => resolve(sub))
          .catch((e) => {
            if (Notification.permission !== 'granted') return console.log('Permission was not granted.');
            return reject(Error('An error ocurred during the subscription process.', e));
          });
      });
    });
  });

  function openModal(key, jsx) {
    setModals((prev) => ({ ...prev, [key]: jsx }));
  }

  function closeModal(key) {
    setModals((prev) => {
      const clonedPrev = { ...prev };
      delete clonedPrev[key];
      return clonedPrev;
    });
  }

  return {
    callerOP,
    setCallerOP,
    socket,
    notifySubscribe,
    settingsModal,
    setSettingsModal,
    callWindows,
    modals,
    openModal,
    closeModal,
  };
};
export default useGlobal;
