import { createSlice } from '@reduxjs/toolkit';

const archiveSlice = createSlice({
  name: 'archive',
  initialState: {
    archivedProjects: [],
    loading: false,
  },
  reducers: {
    setValue: (state, action) => {
      state[action.payload.target] = action.payload.value;
    },
    addArchive: (state, action) => {
      state.archivedProjects = [action.payload, ...state.archivedProjects];
    },
    removeArchive: (state, action) => {
      state.archivedProjects = state.archivedProjects
        .filter((archivedProject) => archivedProject.id !== action.payload.id);
    },
  },
});

export const {
  setValue,
  addArchive,
  removeArchive,
} = archiveSlice.actions;
export default archiveSlice.reducer;
