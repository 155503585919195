import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import IncommingCall from './Components/IncommingCall/IncommingCall';
// import { useGlobalCtx } from './Context/GlobalProvider';
import Routing from './Features/Routing';
import SnackBar from './Features/SnackBar/Snackbar';
// import Settings from './Components/Profile/Components/Settings/Settings';
// import Setting from './Components/Profile/Setting';

export default function App() {
  // const { settingsModal } = useGlobalCtx();
  console.log('Version:kingfisher');
  return (
    <div className="dark:bg-bgDark overflow-hidden">
      <SnackBar />
      <Routing />
      {
        // settingsModal ? <Setting /> : ''
      }
      <IncommingCall />
      <ToastContainer />
    </div>
  );
}
