import React from 'react';
import Btn from '../Btn';
import SVGIcon from '../SVGIcon';
import { Accept, Decline } from '../../assets';

export default function CallBtn({
  title,
  onClick,
}) {
  return (
    <Btn onClick={onClick} className={`${title === 'Decline' ? 'bg-[#DC2626]' : 'bg-[#16A34A]'} w-full flex items-center space-x-[0.7em] px-[0.813rem]`}>
      <SVGIcon Icon={title === 'Decline' ? Decline : Accept} />
      <span className="text-sm font-[500]">{title}</span>
    </Btn>
  );
}
