import React from 'react';
import { useGlobalCtx } from '../../../Context/GlobalProvider';
import CustomAlert from '../Components/CustomAlert/CustomAlert';
import './Wrapper.css';

export default function Wrapper() {
  const { toasts, closeToast } = useGlobalCtx();
  return (
    <section className="snackbarArea">
      {
        toasts?.length > 0 ? toasts.map((toast) => <CustomAlert key={toast?.id} message={toast.message} variant={toast.variant} close={() => closeToast(toast.id)} />) : ''
      }
    </section>
  );
}
