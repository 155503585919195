import React, { Suspense, lazy } from 'react';
import ComLocations from './ComLocations';
import Loading2 from '../Components/Loading2';

function Lazy({ name }) {
  const Component = lazy(ComLocations[name]);
  return (
    <Suspense fallback={<div className="flex items-center justify-center w-screen h-screen"><Loading2 /></div>}>
      <Component />
    </Suspense>
  );
}
export default Lazy;
