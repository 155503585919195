import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthProvider';
import Loading from './Loading';

export default function Private({ children }) {
  const { user, loading } = useAuth();
  if (loading) return <Loading />;
  return user?.email ? children : <Navigate to="/signin" />;
}
