/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  createContext,
  useContext,
} from 'react';
// import useToast from '../Features/SnackBar/Hooks/useToast';
import useGlobal from '../Hooks/useGlobal';

const globalContext = createContext();

export function GlobalProvider({ children }) {
  // const { value } = useToast();
  return (
    <globalContext.Provider value={{ ...useGlobal() }}>
      {children}
    </globalContext.Provider>
  );
}

export const useGlobalCtx = () => useContext(globalContext);
