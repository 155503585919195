import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from '../store';
import { GlobalProvider } from './GlobalProvider';
import { AuthProvider } from './AuthProvider';
import ArchiveProvider from '../Features/Archive/context/ArchiveProvider';

export default function Context({ children }) {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <GlobalProvider>
          <AuthProvider>
            <ArchiveProvider>
              {children}
            </ArchiveProvider>
          </AuthProvider>
        </GlobalProvider>
      </BrowserRouter>
    </Provider>
  );
}
