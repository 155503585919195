import { createSlice } from '@reduxjs/toolkit';

const callSlice = createSlice({
  name: 'call',
  initialState: {
    users: {},
    loading: true,
    focused: null,
  },
  reducers: {
    addUsers: (state, action) => {
      state.users = {
        ...state.users,
        ...action.payload,
      };
    },
    setMedia: (state, action) => {
      const { user, value } = action.payload;
      state.users = {
        ...state.users,
        [user]: {
          ...state.users[user],
          media: {
            ...state.users[user].media,
            ...value,
          },
        },
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFocused: (state, action) => {
      state.focused = action.payload;
    },
  },
});

export const {
  addUsers,
  setMedia,
  setLoading,
  setFocused,
} = callSlice.actions;
export default callSlice.reducer;
